import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import { getToken } from '@iso/lib/helpers/utility';

const App = lazy(() => import('./containers/App/App'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
    exact: false,
  },
  {
    path: PUBLIC_ROUTE.VERIFY_USER,
    component: lazy(() => import('@iso/containers/Pages/Verify')),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() => import('@iso/containers/Authentication/Auth0/Auth0Callback')),
  },
];
function PrivateRoute({ children, ...rest }) {
  let isLoggedIn = useSelector(state => state.Auth.idToken);
  let isLoggedOut = useSelector(state => state.Auth.isLogout);
  if (!isLoggedIn) isLoggedIn = getToken().get('idToken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn && !isLoggedOut ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/admin">
              <App />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
