const apiConst = {
  login: '/auth/login',
  profile: '/profile/me',
  updateProfile: '/profile/update',
  dashboard: '/dashboard/lists',
  forgotPassword: '/auth/forgot-password',
  changePassword: '/profile/change-password',
  resetPassword: '/auth/reset-password/:token',

  // roles
  listRoles: '/admin/list-roles',
  roleStatus: '/admin/update-role-status',
  addRoles: '/admin/add-roles',
  updateRole: '/admin/update-roles',
  deleteRole: '/admin/delete-role',
  roleByID: '/admin/get-role-by-id',
  getAssignedPermissions: '/admin/get-assigned-permissions',
  getAllPermissions: '/admin/list-permissions',

  // category
  listCategory: '/admin/list-categories',
  addCategory: '/admin/add-categories',
  updateCategory: '/admin/update-categories',
  deleteCategory: '/admin/delete-categories',
  categoryStatus: '/admin/update-categories-status',

  // sub-admin
  listSubAdmin: '/admin/list-subadmin',
  addSubAdmin: '/admin/add-subadmin',
  updateSubAdmin: '/admin/update-subadmin',
  deleteSubAdmin: '/admin/delete-subadmin',
  subadminStatus: '/admin/change-subadmin-status',
  getSubadminByID: '/admin/get-admin-data/:id',

  // feeds
  listFeeds: '/admin/list-posts',
  viewFeeds: '/admin/view-post',
  deleteFeeds: '/admin/delete-post',
  reportFeeds: '/admin/report-post',
  filterFeed: '/admin/filter-post',

  // users
  listUsers: '/user/lists',
  addUser: '/users/add_user',
  deleteUser: '/user/delete/:id',
  getUserDetails: '/user/lists/:id',
  userStatus: '/user/update/status/:id',
  updateUser: '/user/update/:id',
  allFamilyMember: '/user/family-members',

  //emails
  listAllEmails: '/email-template/lists',
  listEmail: '/email-template/lists/:id',
  addEmail: '/email-template/create',
  editEmail: '/email-template/update/:id',
  deleteEmail: '/email-template/delete/:id',

  // static-pages
  listStaticPages: '/static-page/lists',
  listStaticPageById: '/static-page/lists/:slug',
  createStaticPage: '/static-page/create',
  updateStaticPage: '/static-page/update/:id',
  deleteStaticPage: '/static-page/delete/:id',
};

export default apiConst;
