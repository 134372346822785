export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/reset-password/:token',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  VERIFY_USER: '/verify',
};

export const PRIVATE_ROUTE = {
  MAIN: '',
  DASHBOARD: 'dashboard',
  EDITPROFILE: 'edit-profile',
  CHANGEPASSWORD: 'change-password',

  // Category
  CATEGORY: 'category',

  // Role
  ROLESLIST: 'roles',
  UPDATEROLE: 'edit-roles/:id',
  ADDROLE: 'add-roles',

  // Sub Admin
  SUBADMINLIST: 'subadmin',
  ADDSUBADMIN: 'add-subadmin',
  EDITSUBADMIN: 'edit-subadmin/:id',

  // feeds
  FEEDLIST: 'feeds',
  FEEDDETAIL: 'feeds/:id',

  // users
  USERLIST: 'users',
  NONUSERLIST: 'family-members',

  // email
  EMAILLIST: 'emails',

  // static-page
  STATICPAGE: 'static-page',
};
