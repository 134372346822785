import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import { handleLogin, getUserDetails, handleUserData } from './api';
import { unHandleError } from '../../constant/error';
import { showNotification } from '../appNotification/sagas';
import permissions from '../../constant/permissions';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

const checkPermissions = roles => {
  let originalPermissions = {};
  if (roles) {
    roles.map(role => {
      originalPermissions = { ...originalPermissions, [role.name]: true };
    });
    return originalPermissions;
  } else return originalPermissions;
};

function* setLoader(value) {
  yield put(actions.setLoader(value));
}
export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    } else if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: 'secret token',
        profile: 'Profile',
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* customLogin() {
  yield takeEvery(actions.CUSTOM_LOGIN_REQUEST, function* ({ payload }) {
    try {
      yield setLoader(true);
      const data = yield call(handleLogin, payload.data);
      if (data.status === 200) {
        const id_token = data?.token;
        const userData = yield call(handleUserData, id_token);
        yield put({ type: actions.CHANGE_LOGOUT_STATE });
        yield put(actions.storeUser(userData?.data));
        yield put(actions.storeRole(userData?.data?.role));
        if (userData?.data?.role)
          yield put(actions.storeOriginalPermission(checkPermissions(userData?.data?.permissions)));
        yield put(actions.login(id_token));
        yield call(setSidebar);
      }
      // else yield call(showNotification, data);
      yield setLoader(false);
    } catch (e) {
      yield setLoader(true);
      yield call(showNotification, { ...unHandleError });
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push('/');
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER_DETAILS, function* () {
    const token = getToken().get('idToken');
    if (token) {
      try {
        const data = yield call(handleUserData, token);
        if (data) {
          yield put(actions.storeUser(data?.data));
          yield put(actions.storeRole(data?.data?.permissions));
          if (data?.data?.role?.permissions)
            yield put(actions.storeOriginalPermission(checkPermissions(data?.data?.permissions)));
          yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            profile: 'Profile',
          });
          yield call(setSidebar);
        } else yield call(showNotification, data);
      } catch (e) {
        yield setLoader(true);
        yield call(showNotification, { ...unHandleError });
      }
    }
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({ type: actions.CHANGE_LOGOUT_STATE });
      try {
        const data = yield call(handleUserData, token);
        console.log('checkAuth: ', data);
        if (data.status === 200) {
          yield put(actions.storeUser(data?.data));
          yield put(actions.storeRole(data?.data?.role));
          if (data?.data?.role)
            yield put(actions.storeOriginalPermission(checkPermissions(data?.data?.permissions)));
          yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            profile: 'Profile',
          });
          yield call(setSidebar);
        } else yield call(showNotification, data);
      } catch (e) {
        console.log(e);
        yield setLoader(false);
        yield call(showNotification, { ...unHandleError });
      }
    }
  });
}
export function* setSidebar() {
  let options = [];
  const userPermissions = yield select(state => state.Auth.permissions);

  console.log('userPermissions: ', userPermissions);
  options.push({
    key: 'dashboard',
    label: 'Dashboard',
    leftIcon: 'fa fa-tachometer',
    is_admin: true,
  });

  // if (userPermissions[permissions.listCategory]) {
  //   options.push({
  //     key: 'category',
  //     label: 'Category Management',
  //     leftIcon: 'ion-ios-paper',
  //     is_admin: true,
  //   });
  // }

  // if (userPermissions[permissions.listRole]) {
  //   options.push({
  //     key: 'roles',
  //     label: 'Roles Management',
  //     leftIcon: 'fas fa-users-cog',
  //     is_admin: true,
  //   });
  // }

  // if (userPermissions[permissions.listSubadmin]) {
  //   options.push({
  //     key: 'subadmin',
  //     label: 'Sub Admins Management',
  //     leftIcon: 'fas fa-users-cog',
  //     is_admin: true,
  //   });
  // }

  // if (userPermissions[permissions.listFeeds]) {
  //   options.push({
  //     key: 'feeds',
  //     label: 'Feeds Management',
  //     leftIcon: 'fas fa-camera',
  //     is_admin: true,
  //   });
  // }

  if (userPermissions[permissions.listUser]) {
    options.push({
      key: 'users',
      label: 'Users Management',
      leftIcon: 'fas fa-user',
      is_admin: true,
    });
  }
  if (userPermissions[permissions.listUser]) {
    options.push({
      key: 'family-members',
      label: 'Family Members Management',
      leftIcon: 'fas fa-users',
      is_admin: true,
    });
  }
  if (userPermissions[permissions.listAllEmails]) {
    options.push({
      key: 'emails',
      label: 'Email Management',
      leftIcon: 'fas fa-envelope',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listStaticPages]) {
    options.push({
      key: 'static-page',
      label: 'Static Page Management',
      leftIcon: 'ion-ios-paper-outline',
      is_admin: true,
    });
  }
  yield put({
    type: actions.SET_SIDEBAR,
    payload: { options },
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(customLogin),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUser),
  ]);
}
