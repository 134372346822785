import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import Logger from './library/helpers/logger';
import Notification from './Notifications';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <React.Fragment>
        <GlobalStyles />
        <Notification />
        <Routes />
      </React.Fragment>
    </AppProvider>
  </Provider>
);

Boot()
  .then(() => App())
  .catch(error => Logger.log(error));

export default App;
