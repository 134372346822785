const permissions = {
  dashboard: 'dashboard_count',
  updateProfile: '/profile/update',

  // // category
  // categoryStatusChange: 'update-categories-status',
  // deleteCategory: 'delete-categories',
  // updateCategory: 'update-categories',
  // addCategory: 'add-categories',
  // listCategory: 'list-categories',

  // user
  updateUser: '/user/update/:id',
  userStatusChange: '/user/update/status/:id',
  userDetail: '/user/lists/:id',
  addUser: '/users/add_user',
  listUser: '/user/lists',
  deleteUser: '/user/delete/:id',

  // // roles
  // assignedPermissions: 'get-assigned-permissions',
  // listAllPermissions: 'list-permissions',
  // roleDetail: 'get-role-by-id',
  // roleStatusChange: 'update-role-status',
  // deleteRole: 'delete-role',
  // updateRole: 'update-roles',
  // addRole: 'add-roles',
  // listRole: 'list-roles',

  // // sub-admin
  // listSubadmin: 'list-subadmin',
  // addSubadmin: 'add-subadmin',
  // updateSubadmin: 'update-subadmin',
  // deleteSubadmin: 'delete-subadmin',

  // // feeds
  // listFeeds: 'list-posts',
  // viewFeeds: 'view-post',
  // deleteFeeds: 'delete-post',
  // reportFeeds: 'report-post',

  // email
  listAllEmails: '/email-template/lists',
  listEmail: '/email-template/lists/:id',
  addEmail: '/email-template/create',
  editEmail: '/email-template/update/:id',
  deleteEmail: '/email-template/delete/:id',

  // static-page
  listStaticPages: '/static-page/lists',
  listStaticPageById: '/static-page/lists/:slug',
  createStaticPage: '/static-page/create',
  updateStaticPage: '/static-page/update/:id',
  deleteStaticPage: '/static-page/delete/:id',
};

export default permissions;
