import React from 'react';
import ImageCellView from './ImageCell';
import DeleteCell from './DeleteCell';
import EditableCell from './EditableCell';
import FilterDropdown from './FilterDropdown';
import moment from 'moment';
import styled from 'styled-components';

const MySearchNote = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #000;
`;

const DateCell = data => <p>{data.toLocaleString()}</p>;
const DateTimeCell = data => (
  <p>
    {data && moment.utc(data).local().format('DD MMM YYYY HH:mm aa')
      ? moment.utc(data).local().format('DD MMM, YYYY HH:mm')
      : 'Not Specified'}
  </p>
);
const ImageCell = src => (
  <ImageCellView
    src={
      src ||
      'https://openxcell-development-private.s3.ap-south-1.amazonaws.com/fanify/images/default.jpg'
    }
  />
);
const LinkCell = (link, href) => <a href={href || '#'}>{link}</a>;
const TextCell = text => <p className="word-break">{text}</p>;

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
  DateTimeCell,
  MySearchNote,
};
