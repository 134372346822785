import axios from 'axios';
import { getToken } from '@iso/lib/helpers/utility';
import { store } from '../../redux/store';
import actions from '../../redux/auth/actions';
import { notification } from 'antd';

const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  get = data => this.api(METHOD.GET, data);

  post = data => this.api(METHOD.POST, data);

  patch = data => this.api(METHOD.PATCH, data);

  put = data => this.api(METHOD.PUT, data);

  delete = data => this.api(METHOD.DELETE, data);

  api = async (method, { message = true, ...data }) => {
    console.log('data', data);
    try {
      const instance = axios.create({
        baseURL: process.env.REACT_APP_API_PATH,
      });

      instance.defaults.headers.common.Authorization =
        getToken().get('idToken') && getToken().get('idToken') != 'undefined'
          ? 'Bearer ' + getToken().get('idToken')
          : undefined;

      instance.interceptors.response.use(
        function (response) {
          if (message) {
            notification.open({
              message: 'Success',
              description: response.data.message,
            });
          }

          return response;
        },
        function (error) {
          return Promise.reject(error);
        },
      );

      const res = await instance({ method, ...data });
      return res.data;
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        store.dispatch(actions.logout());
        notification.open({
          message: 'Success',
          description: e.response.data.message,
        });
        return Promise.reject(e);
      } else {
        notification.open({
          message: 'Error',
          description: e.response.data.message,
        });
        return e;
      }
    }
  };
}

export default Api;
